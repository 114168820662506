body {
  margin: 0;
  /* background-color: #121126; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}
